
/**
 * @description: 计算屏幕宽度
 * @param {}
 * @return: null
 */
 export function deviceW() {
    const docEl = document.documentElement;
    const clientW = docEl.clientWidth
    if(clientW<=900){
        let scaleX = clientW/375
        let fontSize = 20*scaleX
        docEl.style.fontSize = `${fontSize}px`
    }else{
        docEl.style.fontSize = '16px'
    }
    
  
  }
  