

module.exports = {
    path: '/',
    component: () => import('@/layout/index.vue'),
    children:[{
        path:"about",
        name:"about",
        component: () => import('@/views/about/index.vue'),
        meta:{
            title:"关于我们",
            requiresAuth:false
        }
    }]
}