// const Layout = require('@/layout/index.vue');


module.exports = {
    path: '',
    redirect:"/home",
    meta:{
        title:"首页",
        requiresAuth:false
    }
}