import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import '@/assets/styles/m-reset.scss'

import {deviceW} from '@/utils/deviceW.js'

router.beforeEach(async (to, from, next) => {
    
    if(to.meta.title){
        document.title = to.meta.title
    }
    next()
    
})

const app = createApp(App)
// app.config.globalProperties.$deviceW = deviceW // 选项式api使用
app.provide('deviceW',deviceW)

app.use(router).mount('#app')

