

module.exports = {
    path: '/',
    component: () => import('@/layout/index.vue'),
    children:[{
        path:"",
        name:"home",
        alias:"/home",
        component: () => import('@/views/home/index.vue'),
        meta:{
            title:"首页",
            requiresAuth:false
        }
    }]
}